import axios from "axios";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import { graphql } from "gatsby";
import React, { FC, useState } from "react";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";

import { ButtonCta } from "../../../components/Cta";
import { ErrorMessage } from "../../../components/Form/ErrorMessage";
import { InputRadio } from "../../../components/Form/InputRadio";
import { InputText } from "../../../components/Form/InputText";
import SEO from "../../../components/seo";

import * as formStyles from "../../../styles/shared/form.module.scss";
import * as global from "../../../styles/global.module.scss";
import * as quizStyle from "../../../styles/shared/quiz.module.scss";
import * as stylesGuidePerso from "../../../styles/pages/demande/guide-personnalise.module.scss";
interface AirTableNode {
  id?: string;
  data: {
    label: string;
    slug: string;
    famille?: {
      id: string;
      data: {
        label: string;
        slug: string;
      };
    }[];
    vetements?: {
      id: string;
      data: {
        label: string;
        slug: string;
      };
    }[];
  };
}
interface Props {
  data: {
    familiesTable: {
      nodes: AirTableNode[];
    };
    stylesTable: {
      nodes: AirTableNode[];
    };
  };
  location: Location;
}

interface FormProps {
  type_de_vetement: string;
  budget: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  couleur: string;
  motifs: string;
}

export const query = graphql`
  query DemandeGuidePersoQuery {
    familiesTable: allAirtable(
      filter: { table: { eq: "familles_de_vetements" } }
    ) {
      nodes {
        data {
          slug
          label
          vetements {
            id
            data {
              label
              slug
            }
          }
        }
      }
    }
  }
`;

const DemandeGuidePerso: FC<Props> = (props: Props) => {
  const {
    data: { familiesTable },
    location,
  } = props;
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validationSchema = yup.object().shape({
    budget: yup.string().required(),
    email: yup.string().email().required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    phone: yup.string().required(),
    couleur: yup.string().required(),
    motifs: yup.string().required(),
    type_de_vetement: yup.string().required(),
  });
  const sortedGroupedOptions = (datas: AirTableNode[]) => {
    const arr = [];
    datas.map(d => {
      const obj = {
        label: d.data.label,
        options: [],
      };
      d.data.vetements.map(v => {
        const vObj = {
          value: v.data.slug,
          label: v.data.label,
          id: v.id,
        };
        obj.options.push(vObj);
      });
      arr.push(obj);
    });

    return arr;
  };
  const familiesGroupedOptions = sortedGroupedOptions(familiesTable.nodes);
  const onSubmitForm = (values: FormProps) => {
    setIsSubmitting(true);
    console.log("here");
    axios
      .post(process.env.GATSBY_GUIDE_SPECIFIC_FORM_URL, values)
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setIsSuccess(false);
      })
      .then(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <>
      <SEO
        meta={[
          {
            name: "og:url",
            content: location.href,
          },
        ]}
        title="Constant - assistant shopping 100% éco-responsable"
      />
      <div className={cx(global.pageWrapper)}>
        <div className={quizStyle.introContainer}>
          <h2 className={cx(quizStyle.title, global.bigTitle, global.title)}>
            Faites votre demande <br /> de guide personnalisé, en 2min.
          </h2>
        </div>
        <div className={stylesGuidePerso.formWrapper}>
          <div className={stylesGuidePerso.formContainer}>
            {isSuccess ? (
              <div className={global.title}>
                <p>
                  Votre demande a bien été envoyé, je reviens vers vous
                  rapidement !
                </p>
                <p> Merci de participer au changement de la mode 🔥</p>
              </div>
            ) : (
              <Formik
                onSubmit={onSubmitForm}
                initialValues={{
                  type_de_vetement: "",
                  budget: "",
                  email: "",
                  firstname: "",
                  lastname: "",
                  phone: "",
                  couleur: "",
                  motifs: "",
                }}
                validationSchema={validationSchema}
                validateOnChange={true}
              >
                {form => (
                  <Form>
                    <div className={stylesGuidePerso.questionContainer}>
                      <h3
                        className={cx(
                          quizStyle.title,
                          stylesGuidePerso.title,
                          global.title,
                        )}
                      >
                        <span className={stylesGuidePerso.qNb}>1.</span> Quel vêtement
                        cherchez-vous ?
                      </h3>
                      <p className={global.text}>
                        Si aucune des option de la liste ne vous convient,
                        n'hésitez pas à écrire exactement ce que vous
                        souhaitez&nbsp;!
                      </p>
                      <div className={formStyles.formRow}>
                        <div className={cx(formStyles.formGroup)}>
                          <div className={formStyles.formGroupContent}>
                            <Field
                              component={CreatableSelect}
                              name="type_de_vetement"
                              options={familiesGroupedOptions}
                              placeholder={"Écrivez ici..."}
                              createOptionPosition="first"
                              menuPlacement="auto"
                              onChange={el => {
                                form.setFieldValue(
                                  "type_de_vetement",
                                  el.value,
                                );
                              }}
                              isClearable={true}
                              formatCreateLabel={opt =>
                                `Ajouter l'option "${opt}"`
                              }
                            />
                            <ErrorMessage name={"type_de_vetement"} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={stylesGuidePerso.questionContainer}>
                      <h3
                        className={cx(
                          quizStyle.title,
                          stylesGuidePerso.title,
                          global.title,
                        )}
                      >
                        <span className={stylesGuidePerso.qNb}>2.</span> Vous avez une
                        couleur spécifique en tête ?
                      </h3>
                      <p className={global.text}>
                        Vous pouvez aussi écrire plusieurs couleurs ou même des
                        gammes de tons.
                      </p>
                      <div className={formStyles.formRow}>
                        <div className={cx(formStyles.formGroup)}>
                          <div className={formStyles.formGroupContent}>
                            <Field
                              component={InputText}
                              name="couleur"
                              placeholder={"Écrivez ici..."}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={stylesGuidePerso.subQuestionContainer}>
                        <h3
                          className={cx(
                            quizStyle.title,
                            stylesGuidePerso.title,
                            stylesGuidePerso.subQuestionTitle,
                            global.title,
                          )}
                        >
                          <span className={stylesGuidePerso.subQuestionTitleText}>
                            Et niveau motifs ?
                          </span>
                        </h3>
                        <div className={formStyles.formRow}>
                          <div
                            className={cx(
                              formStyles.formGroup,
                              formStyles.radioGroup,
                            )}
                          >
                            <div className={formStyles.formGroupContent}>
                              <Field
                                component={InputRadio}
                                name="motifs"
                                id="veux-des-motifs"
                                label="Je veux des motifs !"
                                showErrorMessage={false}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={formStyles.formRow}>
                          <div
                            className={cx(
                              formStyles.formGroup,
                              formStyles.radioGroup,
                            )}
                          >
                            <div className={formStyles.formGroupContent}>
                              <Field
                                component={InputRadio}
                                name="motifs"
                                id="motifs-non-obligatoires"
                                label="J’aime bien les motifs, mais n’en veux pas forcément."
                                showErrorMessage={false}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={formStyles.formRow}>
                          <div
                            className={cx(
                              formStyles.formGroup,
                              formStyles.radioGroup,
                            )}
                          >
                            <div className={formStyles.formGroupContent}>
                              <Field
                                component={InputRadio}
                                name="motifs"
                                id="pas-de-motifs"
                                label="Pas de motifs."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={stylesGuidePerso.questionContainer}>
                      <h3
                        className={cx(
                          quizStyle.title,
                          stylesGuidePerso.title,
                          global.title,
                        )}
                      >
                        <span className={stylesGuidePerso.qNb}>3.</span> Quel budget
                        souhaitez-vous allouer à cet achat ?
                      </h3>
                      <div className={formStyles.formRow}>
                        <div
                          className={cx(
                            formStyles.formGroup,
                            formStyles.radioGroup,
                          )}
                        >
                          <div className={formStyles.formGroupContent}>
                            <Field
                              component={InputRadio}
                              name="budget"
                              id="budget-raisonnable"
                              label="Un budget raisonnable pour de la slowfashion."
                              showErrorMessage={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={formStyles.formRow}>
                        <div
                          className={cx(
                            formStyles.formGroup,
                            formStyles.radioGroup,
                          )}
                        >
                          <div className={formStyles.formGroupContent}>
                            <Field
                              component={InputRadio}
                              name="budget"
                              id="budget-high"
                              label="J’ai un bon budget, pour du haut de gamme si ça vaut le coup."
                              showErrorMessage={false}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={formStyles.formRow}>
                        <div
                          className={cx(
                            formStyles.formGroup,
                            formStyles.radioGroup,
                          )}
                        >
                          <div className={formStyles.formGroupContent}>
                            <Field
                              component={InputRadio}
                              name="budget"
                              id="budget-very-high"
                              label="Le budget n’est pas une question, du moment que la piece me plait."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={stylesGuidePerso.questionContainer}>
                      <h3
                        className={cx(
                          quizStyle.title,
                          stylesGuidePerso.title,
                          stylesGuidePerso.subQuestionTitle,
                          global.title,
                        )}
                      >
                        Merci pour ces précisions !
                      </h3>
                      <div
                        className={cx(
                          stylesGuidePerso.conclusionTextContainer,
                          global.text,
                        )}
                      >
                        <p>
                          C'est quoi la prochaine étape ? <br />
                          Lorsque vous aurez envoyé votre demande préliminaire,
                          je l'analyse et vous confirme que je peux m'en
                          occuper.
                        </p>
                        <p>
                          Je vous demanderai deux, trois infos supplémentaires,
                          par téléphone ou sms (comme vous préférez) pour cerner
                          au mieux votre style.
                        </p>
                        <p>
                          Ensuite, si vous le souhaitez, vous pourrez confirmer
                          votre demande en procédant au paiement de 55€ (avec un
                          lien que vous receverez par email).
                        </p>
                      </div>
                      <div className={formStyles.formRow}>
                        <div className={cx(formStyles.formGroup)}>
                          <div className={formStyles.formGroupContent}>
                            <Field
                              component={InputText}
                              name="firstname"
                              placeholder={"Votre prenom *"}
                            />
                          </div>
                          <div className={formStyles.formGroupContent}>
                            <Field
                              component={InputText}
                              name="lastname"
                              placeholder={"Votre nom *"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={formStyles.formRow}>
                        <div className={cx(formStyles.formGroup)}>
                          <div className={formStyles.formGroupContent}>
                            <Field
                              component={InputText}
                              type="tel"
                              name="phone"
                              placeholder={"Votre nº de portable *"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={formStyles.formRow}>
                        <div className={cx(formStyles.formGroup)}>
                          <div className={formStyles.formGroupContent}>
                            <Field
                              component={InputText}
                              type="email"
                              name="email"
                              placeholder={"Votre email *"}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <ButtonCta
                          disabled={isSubmitting}
                          btnClassname={cx({
                            [formStyles.disabled]: isSubmitting,
                          })}
                          type="submit"
                          className={"submitBtn"}
                          label={isSubmitting ? "Envoi en cours..." : "Valider"}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DemandeGuidePerso;
