import cx from "classnames";
import { FieldProps } from "formik";
import React, { FC, ReactNode, SyntheticEvent } from "react";
import * as form from "../../../styles/shared/form.module.scss";
import { ErrorMessage } from "../ErrorMessage";

interface Props extends FieldProps {
  id: string;
  label: ReactNode;
  name: string;
  onClick?: (id: string) => void;
  required?: boolean;
  showErrorMessage?: boolean;
}

export const InputRadio: FC<Props> = (props: Props) => {
  const {
    id,
    label,
    onClick,
    required = false,
    field,
    form: { setFieldTouched },
    showErrorMessage = true,
  } = props;
  const onClickInputRadio = (e: SyntheticEvent) => {
    if (typeof onClick === "function") {
      onClick(id);
    }
  };
  const onBlurInput = (e: React.SyntheticEvent) => {
    setFieldTouched(field.name, true);
    field.onBlur(e);
  };
  const onChangeInput = (e: React.SyntheticEvent) => {
    field.onChange(e);
  };
  return (
    <>
      <div className={form.radioGroupContainer}>
        <div className={form.inputContainer}>
          <input
            type="radio"
            id={id}
            value={id}
            name={field.name}
            onClick={onClickInputRadio}
            onChange={onChangeInput}
            onBlur={onBlurInput}
            required={required}
          />
        </div>
        <label className={form.radioLabel} htmlFor={id}>
          {label}
        </label>
      </div>
      {showErrorMessage && <ErrorMessage name={field.name} />}
    </>
  );
};
